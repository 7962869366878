html, body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

body {
  background-color: black !important;
}