.grailgrid {
  height: 100%;
}

.grailgrid-board-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: calc(85vh);
}

.guesses-container {
  color: white;
  text-align: center;
  font-size: 20px;
}

.guesses {
  font-size: 100px;
}

.grailgrid-board {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  height: auto;
  width: 100%;
  max-height: 800px;
  max-width: 800px;
  padding-right: 4%;
  padding-bottom: 5%;
}

.grailgrid-cell {
  background-color: white;
  border: 1px solid black;
}

.game-tiles {
  aspect-ratio: 1/1;
  box-shadow: 0 0 0 1px #c8c8c8;
  box-sizing: initial;
  cursor: pointer;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  position: relative;
  transition: all .2s ease-in-out;
}

.game-tiles:hover {
  background-color: rgb(194, 194, 194);
}

.nav-bar {
  padding: 1rem;
  display: flex;
  flex-direction: row;
}

.nav-col {
  width: 50%;
}

.login-button {
  color: white;
  font-size: 35px;
  cursor: pointer;
}

.login-button:hover {
  color: gray;
}

.login-button-container {
  align-self: center;
  text-align: end;
  padding-right: 3%;
}

.titles {
  background-color: black;
  color: white;
  text-align: center;
  font-size: 20px;
  align-self: center;
  font-weight: 700;
}

.start-over-container {
  text-align: center;
  margin: 1rem;
}

.start-over {
  font-size: 16px;
  padding: 1rem 2rem; 
  background-color: white;
  border: 1px black solid;
  color: black;
}

.media-button {
  padding: 0.25rem 1.5rem;
  background-color: black;
  /* border: 1px #1d9bf0 solid; */
  border-radius: 20px;
  color: white;
  padding-bottom: 8px;
  text-decoration: none;
}

.view-score {
  margin: 1rem;
  padding: 0.50rem 0.75rem;
  border: 1px solid white;
  outline: none;
  font-weight: 500;
}

.view-score:hover {
  background-color: #76688D;
  color: white;
  border: 1px solid #76688D;
}

.search-icon {
  width: 1.5rem;
  height: 1.5rem;
  /* margin-right: 1rem; */
}

.search-input {
  width: 90%;
  border: none;
  outline: none;
}

.modal-dialog {
  top: 20%;
}

.grail-selection-container {
  margin-top: 0.5rem;
  padding-top: 1rem;
  border-top: 1px solid gray;
}

.grail-selection {
  padding: 0.5rem 1rem;
}

.grail-selection:hover {
  background-color: rgb(218, 218, 218);
  cursor: pointer;
}

.selected {
  background-color: #76688D;
}

.correct {
  background-color: white;
  color: white;
  border: 1px solid black;
  aspect-ratio: 1/1;
  box-shadow: 0 0 0 1px #c8c8c8;
  box-sizing: initial;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  position: relative;
  transition: all .2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  text-align: center;
  position: relative;
}

.logo-container {
  padding-left: 5%;
  padding-top: 0.5rem;
  margin-bottom: 1rem;
}

.logo-container img {
  width: 250px;
}

.loading-modal .modal-dialog {
  margin: 0;
  height: 100%;
  max-width: 100%;
  position: initial;
  background-color: #b4b4b421;
  transition: none !important;
}

.loading-modal .modal-content {
  place-items: center;
  background-color: transparent;
  border: none;
  top: 50%;
  bottom: 50%;
}

.loading-modal .spinner-border {
  color: white;
}

.rules-modal .modal-dialog {
  top: 20%;
  max-width: 50%;
}

.rules-details {
  font-size: 20px;
  padding: 0 2rem;
}

.score-container {
  text-align: center;
}

.score-title {
  font-size: 25px;
  font-weight: 700;
  margin: 0;
}

.score-modal .modal-dialog {
  top: 5%;
}

.score-board {
  display: flex;
  flex-wrap: wrap;
  width: 13rem;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.score-tile {
  width: 50px;
  height: 50px;
  border: 1px solid black;
  margin: 2px;
}

.score-tile-correct {
  background-color: green;
}

.disclaimer {
  text-align: center;
}

.board-logos {
  padding: 1rem;
}

.rarity {
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: 500;
  width: 50px;
  background-color: #76688D;
}

.form {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.search-icon-button {
  outline: none !important;
  border: none;
  background-color: white;
}

.field-holder {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
}

.field-holder label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  transition: .3s ease-in-out;
}

.field-holder label, .field-holder input {
  font-size: 1rem;
  width: 100%;
  height: 40px;
}

.field-holder input:focus + label,
.field-holder input:valid + label {
  font-size: 0.75em !important;
  top: -20px;
  pointer-events: none;
}

.form-control:focus {
  border-bottom: 2px solid black !important;
  box-shadow: none !important;
}

.lms-form input, .lms-form select {
  border: none;
  border-radius: 0%;
  border-bottom: 2px solid black;
}

.icon-height {
  height: 2.4rem !important;
}

.toggle-show-icon {
  background-color: transparent !important;
  border: none !important;
  /* border-bottom: 2px solid black !important; */
  border-radius: 0% !important;
  padding: 1px !important;
}

.password-requirements .field-holder {
  width: 100%;
}

.input-group {
  position: initial !important;
}

.input-group-append {
  position: absolute;
  right: 7%;
}

.lms-button {
  font-size: 16px;
  padding: 0.75rem 2rem;
  background-color: black;
  border: 1px black solid;
  color: white;
  margin: 0 1rem;
}

.login-bottom-row {
  text-align: center;
  margin-bottom: 1rem;
}

.login-bottom-row p small {
  color: grey !important;
  font-size: 16px;
}

.login-bottom-row span {
  cursor: pointer;
  text-decoration: underline;
}

.login-bottom-row span:hover {
  color: darkgray;
}

.error {
  color: red;
  font-weight: 600;
}

.bank-score-button {
  border: none;
  outline: none;
  background: black;
  color: white;
}

.bank-score-button:disabled {
  background-color: gray;
}

@media (max-width: 1130px) {
  .rules-modal .modal-dialog {
    max-width: 80%;
    margin: auto;
  }
}

@media (max-width: 831px) {
  .correct {
      font-size: 12px;
      padding: 2px;
  }
}

@media (max-width: 682px) {
  .view-score {
    padding: 5% 10%;
    font-size: 14px;
    margin: auto;
    margin-top: 1rem;
  }
}

@media (max-width: 609px) {
  .rules-modal .modal-dialog {
    max-width: 95%;
    margin: auto;
  }

  .rules-modal h1 {
    font-size: 25px;
  }

  .rules-details {
    font-size: 16px;
    padding: 0;
  }
}

@media (max-width: 568px) {
  .titles {
      font-size: 16px;
      font-weight: 500;
  }

  .grailgrid-board-container {
      flex-direction: column;
  }

  .grailgrid-board {
      padding-right: 15%;
      margin-bottom: 3rem;
  }
}

@media (max-width: 450px) {
  .search-input {
    width: 85%;
    border: none;
    outline: none;
  }
}

@media (max-width: 448px) {
  .logo-container {
      text-align: center;
  }

  .logo-container img {
      /* width: 300px; */
  }

  .titles {
      font-size: 14px;
      font-weight: 400;
  }
}

@media (max-width: 420px) {
  .view-score {
    padding: 5%;
    font-size: 12px;
    margin: auto;
    margin-top: 1rem;
  }
}

@media (max-width: 359px) {
  .rules-modal h1 {
    font-size: 20px;
  }

  .rules-details {
    font-size: 14px;
  }
}